export const symbolMapping = {
    clearsky_day: '/svg/01d.svg',
    clearsky_night: '/svg/01n.svg',
    clearsky_polartwilight: '/svg/01m.svg',
    fair_day: '/svg/02d.svg',
    fair_night: '/svg/02n.svg',
    fair_polartwilight: '/svg/02m.svg',
    partlycloudy_day: '/svg/03d.svg',
    partlycloudy_night: '/svg/03n.svg',
    partlycloudy_polartwilight: '/svg/03m.svg',
    cloudy: '/svg/04.svg',
    rainshowers_day: '/svg/05d.svg',
    rainshowers_night: '/svg/05n.svg',
    rainshowers_polartwilight: '/svg/05m.svg',
    rainshowersandthunder_day: '/svg/06d.svg',
    rainshowersandthunder_night: '/svg/06n.svg',
    rainshowersandthunder_polartwilight: '/svg/06m.svg',
    sleetshowers_day: '/svg/07d.svg',
    sleetshowers_night: '/svg/07n.svg',
    sleetshowers_polartwilight: '/svg/07m.svg',
    snowshowers_day: '/svg/08d.svg',
    snowshowers_night: '/svg/08n.svg',
    snowshowers_polartwilight: '/svg/08m.svg',
    rain: '/svg/09.svg',
    heavyrain: '/svg/10.svg',
    heavyrainandthunder: '/svg/11.svg',
    sleet: '/svg/12.svg',
    snow: '/svg/13.svg',
    snowandthunder: '/svg/14.svg',
    fog: '/svg/15.svg',
    sleetshowersandthunder_day: '/svg/20d.svg',
    sleetshowersandthunder_night: '/svg/20n.svg',
    sleetshowersandthunder_polartwilight: '/svg/20m.svg',
    snowshowersandthunder_day: '/svg/21d.svg',
    snowshowersandthunder_night: '/svg/21n.svg',
    snowshowersandthunder_polartwilight: '/svg/21m.svg',
    rainandthunder: '/svg/22.svg',
    sleetandthunder: '/svg/23.svg',
    lightrainshowersandthunder_day: '/svg/24d.svg',
    lightrainshowersandthunder_night: '/svg/24n.svg',
    lightrainshowersandthunder_polartwilight: '/svg/24m.svg',
    heavyrainshowersandthunder_day: '/svg/25d.svg',
    heavyrainshowersandthunder_night: '/svg/25n.svg',
    heavyrainshowersandthunder_polartwilight: '/svg/25m.svg',
    lightssleetshowersandthunder_day: '/svg/26d.svg',
    lightssleetshowersandthunder_night: '/svg/26n.svg',
    lightssleetshowersandthunder_polartwilight: '/svg/26m.svg',
    heavysleetshowersandthunder_day: '/svg/27d.svg',
    heavysleetshowersandthunder_night: '/svg/27n.svg',
    heavysleetshowersandthunder_polartwilight: '/svg/27m.svg',
    lightssnowshowersandthunder_day: '/svg/28d.svg',
    lightssnowshowersandthunder_night: '/svg/28n.svg',
    lightssnowshowersandthunder_polartwilight: '/svg/28m.svg',
    heavysnowshowersandthunder_day: '/svg/29d.svg',
    heavysnowshowersandthunder_night: '/svg/29n.svg',
    heavysnowshowersandthunder_polartwilight: '/svg/29m.svg',
    lightrainandthunder: '/svg/30.svg',
    lightsleetandthunder: '/svg/31.svg',
    heavysleetandthunder: '/svg/32.svg',
    lightsnowandthunder: '/svg/33.svg',
    heavysnowandthunder: '/svg/34.svg',
    lightrainshowers_day: '/svg/40d.svg',
    lightrainshowers_night: '/svg/40n.svg',
    lightrainshowers_polartwilight: '/svg/40m.svg',
    heavyrainshowers_day: '/svg/41d.svg',
    heavyrainshowers_night: '/svg/41n.svg',
    heavyrainshowers_polartwilight: '/svg/41m.svg',
    lightsleetshowers_day: '/svg/42d.svg',
    lightsleetshowers_night: '/svg/42n.svg',
    lightsleetshowers_polartwilight: '/svg/42m.svg',
    heavysleetshowers_day: '/svg/43d.svg',
    heavysleetshowers_night: '/svg/43n.svg',
    heavysleetshowers_polartwilight: '/svg/43m.svg',
    lightsnowshowers_day: '/svg/44d.svg',
    lightsnowshowers_night: '/svg/44n.svg',
    lightsnowshowers_polartwilight: '/svg/44m.svg',
    heavysnowshowers_day: '/svg/45d.svg',
    heavysnowshowers_night: '/svg/45n.svg',
    heavysnowshowers_polartwilight: '/svg/45m.svg',
    lightrain: '/svg/46.svg',
    lightsleet: '/svg/47.svg',
    heavysleet: '/svg/48.svg',
    lightsnow: '/svg/49.svg',
    heavysnow: '/svg/50.svg',
  };